import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
export const _frontmatter = {
  "menuLabel": "Colors",
  "sortOrder": 5.4
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Color Tokens`}</h1>
    <p className="p-large">Use color tokens to apply color to your UI.</p>
    <p>{`Instead of using color names for our tokens, we use `}<em parentName="p">{`semantic tokens`}</em>{` that represent the intent you are trying to achieve. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Foreground (fg)`}</strong>{` tokens are for text, icons and borders.`}</li>
      <li parentName="ul"><strong parentName="li">{`Background (bg)`}</strong>{` tokens are for surfaces and UI elements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Status`}</strong>{` tokens are for any feedback the user must be aware of.`}</li>
      <li parentName="ul"><strong parentName="li">{`Highlight`}</strong>{` tokens are for non-important elements that you'd like to stand out.`}</li>
    </ul>
    <p>{`Determine each token's intent using the table below.`}</p>
    <ColorSwatches page="color-tokens" mdxType="ColorSwatches" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      